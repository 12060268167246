/* eslint-disable no-unused-vars */
/*

In 2020, EdEHR.org was formed to provide the EdEHR as a service
and provides all the IT services you need, as well as, extensive
orientation and ongoing training for the staff in your learning
center, and your instructional and curriculum faculty.
Everything is hosted in Canada and the cost is much less than
other commercial offerings.
Reach out to info@edehr.org to arrange for a demonstration or
check out the platform with the QR code below.


Over the past couple of years the use of EdEHR has grown and
one of the best parts is the inter-school collaboration and the
formation of the EdEHR Advisory Group. This is a group of faculty,
clinical and instructional design experts who provide guidance
and oversight to the EdEHR project.
Scan the QR code to see the public board where advisors
discuss changes and enhancements needed in the charting
pages. This board was started April 2023.



Add EHR realism to simulations
Bridge theory into practice
Develop competency with digital
technology
Case studies can be levelled
Customizable
Works with learning management
systems (Moodle, Canvas, D2L, etc)
Cost effective
Meet Canadian accreditation
requirements
Encourage critical thinking
Practice clinician-patient
communication
Practice documentation of
medication administration,
assessments, interventions


From 2018 to 2020, the British Columbia Ministry of Advanced
Education, Skills and Training funded the initial EdEHR project
through BCcampus. This funding developed the open source
EdEHR prototype which established the feasibility of the
concept. In March 2020, BcCampus funded the first
prototype use of the EdEHR in a class at UBC School of
Pharmacy.
Also in 2020, the UBC Faculty of Medicine provided some
funding to add the ability to insert external files into EHR
records.
From 2020 until today, BCIT has been the major contributor.
Thanks to BCIT the EdEHR project is now a working and well
used application. Plus, BCIT has started the development of
the Lab Information System inside the EdEHR.
Also since 2020, EdEHR.org has made significant contributions
to the open source EdEHR project.




 */
export function needsAHome () {
  let data = {}
  data.originalDraft = 'We at EdEHR.org enable healthcare educators to prepare their students for real-life.  Too often students\' education is based on out-dated paper charting while the real world is using digital technology. \n' +
    'We at EdEHR.org bring the EdEHR project (a simulated EHR and LIS)  healthcare education. This app puts the student first as it lets pedagogy drive the technology and not the other around. Faculty have full control over content creation. Made in Canada, yet adaptable to anywhere.'
  data.companyDescriptionDraft =
    'We at EdEHR.org enable healthcare educators to prepare their students for real-life.  ' +
    'Too often students\' education is based on out-dated paper charting while the real world is improving the quality of care by embracing digital technology. ' +
    'Or when educators do attempt to bring digital technology into the classroom the applications are very expensive or may impose restrictions on what content is allowed, or are not responsive to the needs of the faculty, or the applications are difficult for students to use.' +
    'We at EdEHR.org bring the EdEHR project (a simulated EHR and LIS)  healthcare education. This app puts the student first as it lets pedagogy drive the technology and not the other around. Faculty have full control over content creation. Made in Canada, yet adaptable to anywhere.'

  return {
    related: {
      title: 'Related resources',
      body: 'Canadian Association of Schools of Nursing (CASN) <a href="https://www.casn.ca/2014/12/casn-entry-practice-nursing-informatics-competencies/">Entry-to-Practice Nursing Informatics Competencies</a>\n'
    },
    more: [
      'BCIT is sponsoring substantial improvements to the EdEHR to support both medical laboratory instruction (we just released a new Urinalysis module and are looking forward to a Chemistry module early in 2024), and for nursing programs (we are releasing much higher fidelity Neurological, Respiratory, Fluid Balance, Allergy, Risk Indicator modules)',
      'Possible to provide educational experience in closed loop medication management. Bar code scanning of medication and patient will be available very soon.',

      'Medication lookups based on the <a href="https://www.canada.ca/en/health-canada/services/drugs-health-products/drug-products/drug-product-database/what-data-extract-drug-product-database.html"> Canadian Drug Product Database</a>',

      'Medication orders can be flagged as Cytotoxic, High Alert, Potential Medication Name Confusion, and Second Signature Required',

      'The EdEHR project is lead by faculty for faculty',

      '    Effective health record documentation is much more than just entering data. First there is the need to learn about critical decision-making based on the evidence. Evidence which comes directly from the patient but also from the electronic health records. These records can be complex and lengthy. As well, healthcare students need to develop communication skills that support the care team who is helping the patient. Developing these skills requires guidance from faculty and practice on the part of the student. Practice within the safety of the simulation setting.',

      'All users access the EdEHR via their learning management system (LMS). The LMS provides the authentication and authorization, which means, the LMS says whether a user is a student or instructor. When student users access the EdEHR they are added into the class list for the EdEHR Activity. Instructor users can see this class list as well as they can view the EdEHR Learning Object and Case Study.'
    ]
  }
}

export function aboutPageText () {
  return {
    // Brand origin story
    // How did you start your brand? What is the story that is unique to your brand?
    title: 'The EdEHR story',
    perceptionMessage: 'EdEHR is collaborative, supportive, useful, student friendly, faculty friendly, and it is part of the team dedicated towards improving healthcare education to improve the quality and efficiency of providing patient care.',
    historyStory: 'From its inception the EdEHR has been a collaboration, designed by and for educators who seek an educational healthcare record system where the needs of the student take priority. As one educator puts it the "EdEHR lets the pedagogy drive the tool, rather than the other way around" \n' +
      'Healthcare educators in British Columbia, Canada, saw the need to provide their students with educational electronic health record system but could not find anything that was feasible, due to extremely high costs as well as inflexible content that was unsuitable for their curriculums.  The provincial government of British Columbia funded the prototype project starting in 2018 through to 2020.  The completion of this initial phase of the project saw the EdEHR used in a University of British Columbia [pharmacy class](https://ches.med.ubc.ca/poster-9-implementing-an-academic-electronic-health-record-in-a-large-class-setting/) with great success. Approximately 200 students completed their in-class assignment, using the EdEHR, without any prior training and without any difficulty. \n' +
      'Thanks to the substantial funding from the Health Sciences program at [BCIT](https://www.bcit.ca/study/health-sciences) (British Columbia Institute of Technology) the EdEHR project is evolving.  Currently, the BSN program is bringing EdEHR into all nine terms. Medication administration with barcode scanning, along with many other new EHR improvements such as clinical order sets, head to toe assessments, SBAR, lab requisitions and reports, medication orders, fluid balance, allergies, integumentary care, etc., will all be used in class this spring of 2024.\n' +
      'BCIT also designed and funded the Lab Information System (LIS) in the EdEHR, and this is currently used by 100\'s of students every term in hematology and urinalysis med lab classes. Work is continuing to support chemistry and more. \n',
    projectTitle: 'EdEHR project history',
    servicesTitle: 'History of the EdEHR.org services',
    historyProject: [
      'The EdEHR project requirements came from the "[Environmental Scan Report](https://edehr.org/assets/EdEHR_Env_Scan_June%204_2018-Published.pdf)" published June 4, 2018 and the project was directed by a stakeholder group from the British Columbia Institute of Technology, the University of British Columbia, and the University of Victoria. Extensive usability studies and the UI design was completed before software development started September 19, 2018. Bryan Gilbert was hired in September 2018 for a 6 month contract to architect and build a prototype. This was successful and since then BCIT has continued to find the work and bring it into the classroom. ',
      'From 2018 to 2020, the British Columbia Ministry of Advanced ' +
      'Education, Skills and Training funded the initial EdEHR project ' +
      'through BCcampus. This funding developed the open source ' +
      'EdEHR prototype which established the feasibility of the ' +
      'concept. ',
      'In March 2020, BcCampus funded the first' +
      'prototype use of the EdEHR in a class at UBC School of ' +
      'Pharmacy.' +
      'Also in 2020, the UBC Faculty of Medicine provided some ' +
      'funding to add the ability to insert external files into EHR records.',
      'From 2020 until today, BCIT has been the major contributor.' +
      'Thanks to BCIT the EdEHR project is now a working and well' +
      'used application. Plus, BCIT has started the development of' +
      'the Lab Information System inside the EdEHR.',
      'Also since 2020, EdEHR.org has made significant contributions' +
      'to the open source EdEHR project.'
    ],
    historyService: [ 'In 2020, EdEHR.org was formed to provide the EdEHR as a service ' +
      'and provides all the IT services you need, as well as, extensive ' +
      'orientation and ongoing training for the staff in your learning ' +
      'center, and your instructional and curriculum faculty. ',
    'Everything is hosted in Canada and the cost is much less than' +
      'other commercial offerings.'
    ]
  }
}
export function aboutData () {
  const data = {}
  data.title = 'placeholder title'
  data.extraTitle = 'extra title'

  data.companyDescription50WordMax = 'EdEHR.org enables  healthcare instructors to prepare their students for the real world which uses complex computer documentation systems. \n' +
    'At EdEHR.org we maintain and provide the EdEHR project; a simulated healthcare documentation system, that puts the student first, as it lets pedagogy drive the technology and not the other around.'

  data.fromPostCard = 'At EdEHR.org we maintain and provide the EdEHR project; a simulated healthcare documentation system, that puts the student first, as it lets pedagogy drive the technology and not the other around.\n' +
    'EdEHR is a user-friendly simulated electronic health record and lab information system designed by educators and tailored for instructors to effectively guide their students in digital health care competency.'

  data.uvpText = 'At EdEHR.org we maintain and provide the EdEHR project; a simulated healthcare documentation system that puts the student first, as it lets pedagogy drive the technology and not the other around.\n' +
    'EdEHR is a user-friendly simulated electronic health record and lab information system designed by educators and tailored for instructors to effectively guide their students in digital healthcare competency.\n' +
    'Nurses can practice all their work including; medication administration, head to toe assessments, discharge planning, interventions and interprofessional communications and more.\n' +
    'Medical laboratory students can practice report documentation and generation in the context of a lab information system.\n' +
    'Medical laboratory administration students can practice intake and accessioning procedures.\n' +
    'PharmTech students can practice medication orders in the context of a patient’s medical history and current conditions.\n'

  data.body =
    'Help your learners develop competency with digital technology.\n' +
    'Never need to reset the patient\'s date of birth in a case study. It\'s easy with EdEHR.\n'+
    'No need managing scripts to reset the data before each class. With EdEHR, each student has their own database space.\n' +
    'Satisfy the requirements from accreditation authorities, such as CASN\n' +
    'Give your learners medical data in a real life context during simulations or theory classes.\n' +
    'Use the EdEHR to make the simulations more realistic.\n' +
    'Enable your learners to practice and developer effective clinician-patient communication skills.\n' +
    'Electronic health records are where healthcare professionals find what they need to know, where they document what they did and where they communicate with other professionals.\n' +
    'Be sure your learners graduate with these competencies.\n\n' +
    'The EdEHR is suitable for all facets of healthcare education\n' +
    'A key EdEHR project goal is to let pedagogy drive the technology and not the other way around.\n' +
    'Learners find the EdEHR easy to use, navigate and they can complete their assignments with little or no prior EHR training.\n' +
    'The EdEHR is designed to encourage critical thinking. For example, decision supports appear in context to assist with judgment and the delivery of safe, quality care.\n' +
    'The EdEHR is designed to work within your learning management system, and to support educators, both in the development of their content, and with their communications with their learners.\n\n' +

    'You have complete control over your case studies, which means you can create content that is relevant to your program and is cost effective. Your content creators simply enter data into the chart just as if they are in the situation.\n' +
    'Laddering of case studies is possible to adapt the content to higher levels of experience\n' +
    'Collaboration between learning centers is happening and is encouraged.\n\n' +
    'The EdEHR is designed to be relevant to Canadian healthcare. For example, it uses the metric system, Canadian medication and dosaging, and it suits the Canadian context and the inclusion of diversity and Indigenous case studies. And this Canadian solution is stored, hosted, and implemented in Canada.'
  return data
}

export function aboutProfiles () {
  const data = {}
  data.title = 'Who we are'
  data.body = 'placeholder body'
  data.extraTitle = 'extraTitle placeholder'
  const bryan = {
    name: 'Bryan Gilbert',
    suffix: 'M.Sc',
    highlight: true,
    role: 'Executive Director',
    bio: 'Bryan\'s multi-decade long career in software development has found a perfect fit with the EdEHR project. ' +
      'He is the founding technical architect and lead developer of this open source EdEHR project. \n' +
      '"I am honoured and privileged to be working with healthcare educators to bring this necessary project to the class-room and simulation lab.  I am deeply grateful to hear how students are really looking forward to using the EdEHR to develop their skills, and I am so happy to provide the EdEHR.org services in collaboration with some amazing healthcare educators."',
    avatar: 'bryan.png'
  }
  const chris = {
    name: 'Chris Hillary',
    suffix: 'M.Sc',
    highlight: true,
    role: 'Lead EdEHR Academic Advisor, Simulation',
    bio: 'Chris has over 15 years of experience in emergency, critical care, and transport nursing. He started his career in the Mineral Springs Hospital in Banff, AB, before moving to the Foothills Medical Center Emergency Department in Calgary, AB, where he spent four years. In 2008, he joined the Interior Health as a critical care transport nurse, an innovative ground transport team. In 2011, Chris took a once in a lifetime opportunity as a critical care flight RN in Canada\'s north, servicing air access only communities in Nunavut and the Northwest Territories. During this time, he became a clinical educator for the same aeromedical program. He has extensive experience in working with a multi-disciplinary cohort and an indigenous population, as well as in challenging and remote environments. \n' +
      '\n' +
      'Chris has been teaching as a nursing educator at Selkirk College since 2008, involved in simulation and virtual simulation facilitation, development, programming, and development; educational development; team-based learning; educational technologies and learning management system support; and special projects, including the Educational Health Record (EdEHR). ',
    avatar: 'ChrisHillary.png'
  }
  const tammy = {
    name: 'Tammie Hardie',
    suffix: 'BA, MLT/RT, CAE',
    role: 'EdEHR Med Lab Academic Advisor\nMed Lab Simulation Coordinator, BCIT',
    avatar: undefined,
    bio:
      'Tammy conceived of the idea of using the EdEHR as a laboratory information system (LIS).  ' +
      'She is currently BCIT\'s med lab simulation coordinator and is faculty in the Medical Laboratory Sciences Program. ' +
      'Tammy brings a depth of clinical laboratory experience from across all of BC\'s health authorities, and she used this ' +
      'experience to guide the design and development of the laboratory information system (LIS) module in the EdEHR. ' +
      'Tammy integrated the way the different regions processed lab requests into an approach that prepares graduate technologists to be ready to get into the real-life lab and get to work right away with confidence.\n' +
      'The LIS module was introduced to the Hematology lab last year, and within weeks the team of instructors went from new concept to managing classes with almost a 100 students per lab.  Everything went really well and now the Urinalysis classes are working in the LIS module.' +
      ''
  }
  const rob = {
    name: 'Rob Kruger',
    suffix: 'RN MEd CNCC(C)',
    role: 'Co-Founder EdEHR project',
    avatar: 'rob.jpeg',
    bio: 'Rob Kruger RN MEd CNCC(C)\n' +
      'Rob is an experienced Nursing Instructor with a demonstrated history of working in the higher education industry. He is skilled in Critical Care Medicine, Educational Technology, Technical Writing, Healthcare, and Critical Thinking. He is a strong healthcare services professional with a Masters focused in Educational Technology and Learning Design from Simon Fraser University. \n' +
      'Rob coauthored the <a href="https://edehr.org/assets/EdEHR_Env_Scan_June%204_2018-Published.pdf"> Environmental Scan Report</a> which laid out the foundation for the EdEHR project, and Rob is a co-founder of the EdEHR project.  He provided guidance during the early formative years of the EdEHR project, based on his deep experience in critical care nursing and nursing education. \n' +
      'Rob co-authored the open source education resource <a href="https://pressbooks.bccampus.ca/healthcasestudies/"> Health Case Studies </a> funded by BC Campus in collaboration with local Health Authorities and BC Nursing Schools. Some of the case studies in this book have been implemented in the EdEHR.' +
      ''
  }
  const joan = {
    name: 'Joan Humphries',
    suffix: 'RN, PhD',
    role: 'Lead EdEHR Academic Advisor',
    avatar: 'joan.png',
    bio: 'Joan has an extensive background in clinical nursing, nursing education, and research. For the past five years, Joan has served in the capacity of Academic Coordinator for the University of Victoria and College Partners. During that time, she has led a curriculum refresh, which has involved the integration of information technology into the four- year BSN program. The opportunity to be a part of EdEHR at this stage of its development, and incorporate it into the curriculum has proven to be valuable on many levels. Faculty and students throughout the partnership are benefiting from its use in the skills lab as well as in the context of simulation. The promise for EdEHR to contribute to student learning is immense.'
  }
  const lin = {
    name: 'Lin Cavelti',
    suffix: '',
    avatar: 'lin-profile-pic2.jpg',
    role: 'Vice President',
    bio: 'As the founder of C2 Enterprises Ltd, Lin has played a pivotal role in developing product brands that have achieved remarkable success, establishing a prominent presence in the global retail landscape.\n' +
      'Lin\'s professional strengths include exceptional organizational and communication skills, and these support her success as she identifies synergies between companies and fosters mutually beneficial relationships.\n' +
      'Lin leads her team on the path towards building successful collaborative programs, recognizing the potential for shared success and growth.\n' +
      'In her role as VP, Lin shares her expertise in project management, market research, effective communication and developing successful brands.'
  }
  const jonathan = {
    name: 'Jonathan Longe',
    suffix: '',
    avatar: 'jonathan.jpeg',
    role: 'Technical strategy',
    bio: 'Jonathan is an experienced full-stack software developer with a passion for transforming paper-based, manual processes into streamlined, digital processes. Jonathan\'s experiences co-founding multiple SaaS web applications has provided EdEHR.org many valuable insights that have helped shaped the EdEHR.org service offering.  He also shares his experience leading software development projects that delight customers and deliver value for the public sector.'
  }
  const glynda = {
    name: 'Glynda Rees',
    suffix: 'RN, MSN, ACHIP, CPHIMS-CA, FAMIA',
    role: 'Co-Founder EdEHR\nLead EdEHR Academic Advisor',
    avatar: 'glynda.jpeg',
    highlight: true,
    bio: 'Glynda is co-founder and leading academic advisor of the EdEHR project.\n' +
      'Glynda is Program Lead for the Advanced Certificate in Digital Health at the British Columbia Institute of Technology (BCIT). Her interests include the integration of Informatics and digital health in undergraduate and post-graduate education, and the impact of technology on clinical judgment and decision-making at the point of care. \n' +
      'Glynda co-authored two open source education resources (Clinical Procedures for Safer Patient Care and Health Case Studies) funded by BC Campus in collaboration with local Health Authorities and BC Nursing Schools. She is co-founder of the Interprofessional open source Educational EHR <a href="https://edehr.org"> EdEHR </a> project\n' +
      'Glynda represents the School of Health Sciences on the Research Ethics Board at BCIT, and is a digital health peer leader and content developer for CASN and Canada Health Infoway. Glynda is a former Board member and President of the Canadian Nursing Informatics Association, a Fellow of the American Medical Informatics Association, and a member of the AMIA Education and Nursing Informatics Working Groups.'
  }
  const joyce = {
    name: 'Joyce Law',
    suffix: 'RN, BSN, BSc, CCSNE',
    role: 'Faculty, BCIT',
    bio: 'Joyce is on nursing faculty at BCIT’s Bachelor of Science in Nursing program. She is a certified simulation educator and key in leading the implementation of EdEHR into the nursing program. She holds an advance certificate in neonatal nursing specialty and continues to work bedside in the NICU. Joyce has used her experiences to work extensively in the development of EdEHR to meet the needs of nursing students.\n' +
    'Joyce recognizes the importance of student acceptance and effective orientations to EHR systems. She has been creating escape rooms and seek and find simulations to promote active orientation to EdEHR. EdEHR will be utilized in simulation education to support nursing student competency in EHR and to support clinical judgement and safe, quality patient care.'
  }
  data.profiles = [
    bryan,
    lin,
    jonathan,
    glynda,
    tammy,
    chris,
    joan,
    rob,
    joyce
  ]
  return data
}

const MAIN_UVP = 'EdEHR enables you to prepare your healthcare students, across all programs, to graduate and immediately be a positive asset, in any health care team, because they will feel confident and empowered to tell their patient\'s story through modern digital technology.  This will lead to better health care outcomes.'

export function bannerText () {
  return {
    title: 'Modern healthcare professionals require proficiency in digital documentation to stay engaged with their patients and excel within intraprofessional teams.',

    introText_A: 'Empower your healthcare students to master digital charting to narrate more impactful patient stories, improve and elevate healthcare team collaboration, and achieve better patient outcomes.\n',
    introText: 'Enable your healthcare students to excel in digital charting.\n' +
      'This enables them to create impactful patient narratives, enhance collaboration within and across healthcare teams, and ultimately, drives improved patient outcomes.',
    introTwo_1: 'The EdEHR is an innovative educational electronic health record and laboratory information system designed in collaboration with healthcare educators.',
    introTwo_A: 'The EdEHR is a fully customizable platform and specifically created for interprofessional healthcare programs. Thanks to the collaboration with healthcare educators the EdEHR transcends technology training and emphasizes pedagogical principles to cultivate healthcare learners who will truly excel in their field.',
    introTwo_C: 'Using the EdEHR in both theory, lab and simulation settings will promote your students\' digital competency and their ability to navigate, interpret, and most importantly, communicate relevant patient data to the right members of their healthcare team.  This will make a tangible difference in patient safety and the quality of care the patient receives. It also has a direct impact on operational efficiency and healthcare team collaboration.',
    introTwo_D: 'Teaching students to tell a meaningful patient narrative, rather than just recite data, will make a difference between simply producing a passing student and developing a quality healthcare professional who brings value to any healthcare setting.',


    introTwo_L: 'The landscape of healthcare education is evolving, and our educational electronic health record system, uniquely designed for interprofessional learning, is at the forefront of this evolution. EdEHR is a fully customizable, pedagogy-driven digital learning solution that prioritizes educational values over technology training, ensuring your healthcare learners are not simply digitally competent but excel in their field.\n',


    ctaText: 'Get started',

    otherText: 'Empower your students to master their digital competencies.  Watch this four-minute video to see how.'
  }
}
export function simExpoText () {
  return {
    introText: 'Come visit us at booth #11 and see how the ' + MAIN_UVP,
    contact: 'Contact us <a href="./contact"> here </a> for more information.\n',
    cta: 'Visit our <a href="./#"> home page </a> to learn more.\n',
    ctaEmail: 'Reach out to <a href="mailto:info@edehr.org"> info@edehr.org </a> for more information.\n'
  }
  /*
            Welcome to the EdEHR, an Educational Electronic Health Record (EHR) system that
          contains a Lab Information System (LIS).  This application is ready to help future health care professionals practice their digital competencies.

   */
}


export function ctaText () {
  return {
    meetingRegistrationLink: 'https://us02web.zoom.us/meeting/register/tZ0kcOiurD4jGdL_OkLDgqXBORDqavlF2Wk3',
    someMeeting: 'https://us02web.zoom.us/j/83739554553?pwd=YzQ5ZkNHeFdLa3VjL3pBbFpQY01SUT09',
    jan31Webinar:      'https://us02web.zoom.us/meeting/register/tZcqdOCtqz8vH9cGnupHi8kPzpqE19eHZM-n',
    feb1Webinar: 'https://us02web.zoom.us/meeting/register/tZcqdOCtqz8vH9cGnupHi8kPzpqE19eHZM-n'
  }
}

export function curriculumSupports () {
  return {
    title: 'Curriculum Supports',
    menuText: 'For creators',
    benefits: [
      'Build your content to suit your curriculum.',
      'We will support you as you build your simulations',
      'See helpful tips when you need them right where you need them.',
      'Learn from video tutorials.',
      'Join into interactive webinars.',
      ''
    ]
  }
}

export function homeAboutText () {
  const data = {
  }
  data.titleA = 'Proficiency in digital communication is an important key to nurturing and developing top-tier healthcare professionals'
  data.title = 'Modern healthcare professionals require a real proficiency in digital documentation.'
  data.main = 'EdEHR promotes digital competency which is essential to your student\'s success. Their ability to navigate, interpret, and most importantly, communicate relevant patient data to the right members of his or her healthcare team will make a tangible difference in the quality of care the patient receives. It also has a direct impact on operational efficiency and healthcare team collaboration. \n Teaching students to tell a meaningful patient narrative, rather than just recite data, will make a difference between simply producing a passing student and developing a quality healthcare professional who brings value to any healthcare setting.'
  return data
}

export function fourUsers () {
  const institutions = {
    title: 'Educational institutions',
    ref:'forManagement',
    header: 'Prepare your medical students for the real world',
    image: 'confidence',
    icon: 'chalkboard-teacher',
    body: 'The real world uses complex digital documentation systems. Your students need to have hands-on experience using these systems. At EdEHR.org we maintain and provide the EdEHR project; a simulated healthcare documentation system that puts the student first, as it lets pedagogy drive the technology and not the other around. '
  }
  const designers = {
    title: 'Curriculum designers',
    ref: 'forCreators',
    header: 'Create content that fits your program needs',
    image: 'design',
    icon: 'chalkboard-teacher',
    body: 'We will help you build your content. You can use built-in case studies or add your own. Customize the content to suit your curriculum. You can also engage and share content with the EdEHR community. And do not forget that you can use the same instance of EdEHR across all disciplines within your school '
  }
  const instructors = {
    title: 'Faculty/Instructors',
    ref: 'forFaculty',
    header: 'Teach with confidence',
    image: 'teach',
    icon: 'chalkboard-teacher',
    body: 'Designed for educators, our platform provides an array of resources to teach electronic charting effectively. From on-demand tips and video tutorials to seamless integration with your school\'s systems, EdEHR ensures a smooth, efficient, and engaging EHR education process. Our tools are tailored to not only educate but also evaluate students within the e-charting context, reinforcing their learning every step of the way.'
  }
  const students = {
    title: 'Students',
    ref: 'forStudents',
    header: 'Students love the EdEHR',
    image: 'study',
    icon: 'chalkboard-teacher',
    quoteList: [
      'Easy to use and navigate.',
      'I would love to have this as part of the curriculum!',
      'This was awesome!!',
      'It was very user friendly and easy to use.',
      'It would be great to use this in lab and theory courses.',
      'Why don\'t we have access to this from year one?',
      'It makes me realize that the EHR does not dictate my practice.',
      'A very helpful and realistic platform!'
    ]
  }
  return {
    students, institutions, designers, instructors
  }
}
export function instructorSupports () {
  return {
    title: 'Instructor Supports',
    menuText: 'For instructors',
    benefits: [
      'Teach with confidence. ',
      'Free faculty support to help learn electronic charting, so you can teach electronic charting.',
      'Instant Prompts. See helpful tips when you need them right where you need them.',
      'Free access to video tutorials.',
      'Free webinars.',
      'One click access to online documentation with step by step guidance.',
      'Easily switch to experience what your students will see.',
      'Automatic class lists based on your school\'s learning management system.',
      'Easy as possible student evaluation.',
      'Mentor your students in the e-charting context',
      'Give students feedback in the e-charting context.',
      'Control when students can view your feedback.',
      'Lock down the activities when time is up.',
      'Easily allow students to resume their work.',
      'Skills assessment mode limits student access to the activities of your choice.',


    ]
  }
}

export function productFeatures () {
  const mar = {
    refId: 'mar',
    image: 'mar',
    imageFn: () => window.location + '/../assets/charts/mar.png',
    title: 'Medication administration',
    icon: 'first-aid-kit',
    body:
      'Medication administration practice is ....',
  }
  const vitals = {
    refId: 'vitals',
    image: 'vitals',
    title: 'Vital signs',
    icon: 'first-aid-kit',
    body:
      'Vitals and vitally important ....',
  }
  return {
    title: 'Product Features',
    menuText: 'Product Features',
    list: [
      mar,
      vitals,
    ]
  }
}

export function productHighlights () {
  const canadian = {
    regions: ['ca'],
    title: 'Canadian',
    icon: 'maple-leaf',
    key: 'canadian',
    faIcon: 'canadian-maple-leaf',
    textHtml:
      'Can be stored, hosted, and implemented in Canada and is relevant to Canadian health care systems (e.g. metric, Canadian medication and dosaging, Canadian context and focus), and can be adapted to include our Indigenous heritage.',
  }
  const medAdmin = {
    title: 'The "Rights" of Medication Administration',
    faIcon: 'medkit',
    textHtml: '<h3>Building Safe Habits with EdEHR</h3> <p></p> <p>Practicing the "rights" of medication administration—right patient, medication, dose, route, time, and documentation—can feel overwhelming. However, each right ensures safety by verifying critical factors to prevent errors. In EdEHR, this information is easily accessible, bridging the gap between theory and practice. By making key details visible and easy to find, EdEHR helps students develop safe, accurate habits, fostering a strong foundation for confident, patient-centered care.</p>'
  }
  const caseStudies = {
    title: 'Your content',
    key: 'content',
    icon: 'doctor',
    faIcon: 'user-injured',
    textHtml:
      'EdEHR gives you full control over your content, and we are here to help you develop it. As well, the EdEHR community is sharing content so that no one needs to do all the work. We can also show you how to use AI to enhance your case studies.',
  }
  const collaboration = {
    title: 'Collaboration for education',
    key: 'collaboration',
    faIcon: 'handshake',
    textHtml:
      'In collaboration with our partners, EdEHR is building this e-charting application to fit pedagogical requirements. Lead instructors from EdEHR supporting schools and health providers design and guide the implementation of this application. \n' +
    'The most significant collaboration is from the [Health Sciences](https://www.bcit.ca/study/health-sciences) program at the British Columbia Institute of Technology (BCIT).  In collaboration with EdEHR.org the BSN program is gradually bringing EdEHR into all nine terms. Medication administration with barcode scanning, along with many other new EHR improvements such as clinical order sets, head to toe assessments, nurses hand over notes, SBAR notes, lab requisitions and reports, medication orders, fluid balance, allergies, integumentary care, etc., and etc.\n' +
      'BCIT also designed and funded the Lab Information System (LIS) application within the EdEHR. This unique approach of blending two normally distinct healthcare applications (EHR and LIS) into the EdEHR allows us to leverage the student and faculty focused features for both these domains. ' +
      'Currently the LIS application is used for all hematology and urinalysis med lab classes, and work is continuing to support chemistry and more. \n' +
    'Join our community and help lead the future with others in the [EdEHR Advisory Group](https://trello.com/b/4aWuezdO/edehr-advisors). \n'
    ,

  }
  const cost = {
    title: 'Cost efficient',
    key: 'lowCost',
    icon: 'medical-support',
    faIcon: 'hand-holding-medical',
    textHtml:
      'The services provided by EdEHR are far more cost effective than using internal school IT and support resources, and EdEHR is very affordable compared to most other educational EHRs.\n' +
      'The cost to use the EdEHR application for a student doing a four year BSN program is less than the cost of a single average text books.\n' +
      'Your school can use your instance of EdEHR across any and all healthcare programs.'
  }
  const customization = {
    title: 'Customizations',
    key: 'customization',
    icon: 'hexagon',
    faIcon: 'dice-d20',
    textHtml:
      'The EdEHR team can work with your subject-matter experts (medical or pedagogical) and provide optional software development services that can enhance the EdEHR project for your benefit and the benefit of the larger educational community.',
  }
  const decision = {
    title: 'Decision supports',
    key: 'decisionSupports',
    faIcon: 'notes-medical',
    textHtml:
      'EdEHR provides tools to assist with judgement and delivery of safe, quality care. For example the medication lookup field searches a customizable drug database, and the bar-code medication administration workflow is seamless.  The EdEHR contains other helpers and many more are planned. As well, EdEHR activities provide place for faculty to give students links to educational resources, guidelines, videos, glossaries, etc.',
  }
  const diversity = {
    title: 'Diversity and inclusion',
    key: 'diversity',
    faIcon: 'globe',
    textHtml: 'The EdEHR project strives to reflect the future of healthcare record keeping. It encompases some aspects of inclusiviness and the roadmap for 2024 includes plans to implement the [HL7](https://confluence.hl7.org/display/VOC/The+Gender+Harmony+Project) diversity standards'
  }
  const easyInstructor = {
    title: 'Faculty supports',
    key: 'facultySupports',
    faIcon: 'chalkboard-teacher',
    textHtml: 'Some faculty may wonder how they can teach digital technology when they themselves have not had experience with digital technology.  The EdEHR provides a quick and easy way for faculty to assume the student role for any activity.  This means faculty can easily validate the content is ready for the class, and they can explore the EHR as the student sees it.  They can also use this feature to present orientation for the students.\n' +
      'Beyond this affordance, the team at EdEHR is here to fully support your faculty through to a successful implementation. ' +
      'We can share ideas and knowledge gleaned from other faculty have tried and are continually striving ' +
      'to make this application so easy that faculty can use it seamlessly without our help.'

  }
  const intraprofessional = {
    title: 'Intraprofessional',
    key: 'intraprofessional',
    faIcon: 'users-cog',
    textHtml:
      'The EdEHR allows users to explore the whole application without limitation.  This freedom can support mentoring students in the concepts of intraprofessional nursing collaboration, and help in the development of a solid understanding the roles and skills of each member of the healthcare team. This knowledge is needed when it comes time to decide which member of the healthcare team is best equipped to solve a healthcare problem.  It is also required for effective intraprofessional team communication, via electronic charting.\n' +
      'Healthcare students need practice to develop a solid understanding of the concept of [collaboration](https://menj.journals.ekb.eg/article_126933_139b29772f82fd692cec7b69b901f3b6.pdf) and how to apply it in the clinical setting',
  }
  const interprofessional = {
    title: 'Interprofessional',
    key: 'interprofessional',
    icon: 'heart',
    faIcon: 'heartbeat',
    textHtml:
      'You can use your instance of EdEHR across all your healthcare programs including; nursing, med lab, physiotherapy, medicine, pharmacy, and more.  Contact us if you need some accommodations for a healthcare speciality. ',
  }
  const laboratory = {
    title: 'Lab Information System',
    key:'labSystem',
    faIcon: 'heartbeat',
    textHtml:
      'The EdEHR project is more than an EHR. It is also an educational Laboratory Information System.  See [#collaboration](collaboration) above.',
  }
  const laddered = {
    title: 'Laddered case studies',
    key:'laddered',
    faIcon: 'user-clock',
    textHtml:
      'Case studies can be adapted to the level of the student accessing the EdEHR. For example, a novice student can work with a simpler case than a more experienced student. As well, the novice student can access, analyze, and respond to data that is less complex than for a more experienced student.',
  }
  const authentic = {
    title: 'Authentic student experience',
    key: 'authentic',
    faIcon: 'users-cog',

    textHtml: '' + ' WORK IN PROGRES ...' +
      'Educators know the importance of using simulation to provide students a safe place to practice.  You also know the importance of educators mentoring their students in the simulation and debriefing classrooms. You are also continually seeking ways to make their simulations more authentic and experiential.'
  }
  const lms = {
    title: 'Use your LMS to manage the EdEHR',
    key: 'lms',
    faIcon: 'school',
    textHtml: 'With EdEHR students have access to EdEHR directly from your learning management system.\n'+
      'The EdEHR automatically follows the courses and the activities that your create in your learning management system.  It will automatically manage class-lists and keep them in sync with your LMS.  This means your faculty can easily control who has access to the EdEHR via their LMS.'
  }
  const m17 = {
    title: 'Multinational',
    key: 'multinational',
    faIcon: 'globe',
    textHtml: 'With help from educators we support students who speak English, French, and Spanish. Current translations are being reviewed by subject matter experts. Any other language can be added.'
  }
  const openSource = {
    title: 'Open Source',
    key: 'openSource',
    faIcon: 'book-open',
    textHtml:
      'EdEHR is an open source project under a copy-left license. Everyone is free to see, use or distribute the software, but the copy-left licensing requires that all modifications stay open source and be available to everyone. \n' +
      '[EdEHR](https://edehr.org) manages the official repository of the EdEHR project and collaborates with partner educational institutions to enhance the application through custom development. This development benefits everyone.',
  }
  const privacy = {
    title: 'Privacy',
    key: 'privacy',
    faIcon: 'lock',
    textHtml: '' +
      'No other educational charting application does as much as we do to protect the privacy of your students. '
  }
  const simulationLab = {
    title: 'Simulation lab data staging',
    faIcon: 'clock',
    key: 'simulationLab',
    textHtml:
      'Stage the release of medical information to your students so you can mentor them in best practices responding to evolving medical and patient care needs. ',
  }
  return {
    title: 'Application Highlights',
    menuText: 'Application Highlights',
    list: [
      canadian,
      medAdmin,
      // authentic,
      caseStudies,
      collaboration,
      cost,
      customization,
      decision,
      diversity,
      easyInstructor,
      simulationLab,
      interprofessional,
      intraprofessional,
      laboratory,
      laddered,
      lms,
      m17,
      openSource,
      privacy
    ]
  }
}

export function quoteText () {
  const michele = {
    key: 1,
    avatar: undefined, // require('@/assets/avatar.png')
    brief: 'this tool will transform the way we teach and learn',
    additional: 'BCIT',
    name: 'Michele Bridge',
    quote: 'It’s not an overstatement to say that this tool will transform the way we teach and learn in the School of Health Sciences.'
  }
  const glynda = {
    name:'Glynda Rees',
    credentials: 'RN, MSN, ACHIP, CPHIMS-CA, FAMIA',
    additional: 'Program Lead Digital Health Advanced Certificate, BCIT\n' +
      'Co-Found EdEHR',
    brief: 'EdEHR supports our students... understanding electronic charting, ... to inform their clinical decision\n' +
      'making and workflow.',
    quote: 'The EdEHR.org service provides an opportunity for our School of Health\n' +
      'Sciences, at BCIT, to integrate the EdEHR into our nursing and MedLab\n' +
      'programs to optimize experiential learning in preparation for clinical practice.\n' +
      'EdEHR supports our students in understanding electronic charting, practicing\n' +
      'documentation, and retrieving information to inform their clinical decision\n' +
      'making and workflow. In addition, the program allows faculty to review\n' +
      'student charting through their LMS system, creating several different learning\n' +
      'and assignment opportunities for students. Nursing students are thus\n' +
      'prepared to practice in clinical environments without the EHR impeding their\n' +
      'interactions with patients and colleagues.'
  }
  const chris = {
    key: 2,
    avatar: undefined, // require('@/assets/avatar.png')
    additional: 'Selkirk College',
    name: 'Chris Hillary',
    brief: 'EdEHR lets the pedagogy drive the tool, rather than the other way around',
    quote: 'Selkirk has been able to incrementally rollout EdEHR in alignment with nursing curriculum at a pace that is comfortable' +
      'for faculty and students, without an imperative to  adopt a complete homework suite with contextually irrelevant content from a large publisher. \n' +
      'As nursing educators, we have an increasing imperative to address the entry level competencies around health care informatics.\n' +
      'I appreciate that EdEHR lets the pedagogy drive the tool, rather than the other way around.'
  }
  const joan = {
    key: 3,
    avatar: undefined, // require('@/assets/avatar.png')
    additional: 'University of Victoria, BSN Partnership',
    credentials: 'RN PhD',
    name: 'Joan Humphries',
    brief: 'The possibilities for integration of the EdEHR into BSN programming are indeed endless!',
    quote: 'We became excited about the possibilities to extend the use of the EdEHR into progressive courses in the lab and simulation settings, as well as ideas surrounding its use in theory courses. Examples of learning activities that were developed include a case study and documentation of a head-to-toe assessment, a patient admission, a simulated lab experience pertaining to mental health, acute care and other realistic nursing scenarios that reflect a student’s progression through the BSN program.\n' +
      'The possibilities for integration of the EdEHR into BSN programming are indeed endless!\n'
  }
  const kerryAnne = {
    quote: 'I think the strongest aspect of this project is how you have worked with educators ( and supported us) to continually refine the EdEHR to meet our needs. This is not always done with a lot of pre-packaged Ed Tech. The EdEHR is not a product per se but a service you are providing, as you say below. ' +
    'In one instance, a learner, when she could not choose an option that fit with what the patient was describing, wrote a note in the comments. It was a great learning moment and something the group was going to take back to clinical practice.  ',
    name: 'Kerry-Ann Dompierre',
    credentials: 'MN, RN',
    brief: 'It was a great learning moment',
    additional: 'Simulation Education Coordinator, Camosun College, School of Health & Human Services\n' +
      'Instructional Designer | The Dr. Lloyd Morin Centre for Excellence in Teaching and Learning (CETL)'
  }
  const blank = {
    quote: '',
    name: '',
    brief: '',
    credentials: '',
    additional: ''
  }
  const kristen = {
    key: 23,
    avatar: undefined, // require('@/assets/avatar.png')
    additional: 'Senenca College',
    name: 'Kristen Riberdy',
    brief: 'allows faculty to review student charting through their LMS system',
    quote: 'At Seneca College, we will integrate the EdEHR into our nursing programs in class, lab, and simulation. EdEHR will allow our students to understand electronic charting, practice documentation, and incorporate it into real-time learning during simulation. In addition, the program allows faculty to review student charting through their LMS system using an LTI, creating several different learning and assignment opportunities for students.'
  }
  const sonja = {
    name: 'Sonja Turnbull',
    additional: 'Instructor, Hematology, BCIT',
    brief: 'Bryan is receptive to our needs and ... incorporates our feedback, almost in real time.',
    quote: 'The EdEHR program is an excellent Electronic Health Record simulation program. It incorporates all facets of medicine – Nursing, Pharmacy, Laboratory, and even Medical Imaging. ... Students find the system very user friendly and intuitive. ... Bryan has been very easy to work with. He is receptive to our needs and works diligently to make the changes and incorporate our feedback, almost in real time. The result has been a program that  is constantly evolving into an efficient and effective educational tool. I would recommend any educational institution consider using EdEHR in their medical programs as a complement to didactic studies. '
  }
  return {
    title: 'What educators say',
    menuText: 'Testimonials',
    extraTitle: 'Testimonials',
    quotes: [
      michele,
      sonja,
      chris,
      kerryAnne,
      joan,
      kristen,
      glynda,
    ]
  }
}

export function studentQuotes () {
  return {
    title: 'Students love the EdEHR',
    menuText: 'Student quotes',
    // subTitle: 'Voice of the student',
    quoteList: [
      'Easy to use and navigate.',
      'I would love to have this as part of the curriculum!',
      'This was awesome!!',
      'It was very user friendly and easy to use',
      'It would be great to use this in lab and theory courses',
      'Why don\'t we have access to this from year one?',
      'It makes me realize that the EHR does not dictate my practice.',
      'A very helpful and realistic platform!'
    ]
  }
}

export function newsAndUpdates () {
  return {
    title: 'News and Updates'
  }
}


let quotes=  {
  kirsten: {
    text: 'At Seneca College, we will use the <a href="edehr.org">EdEHR.org</a> service to integrate the EdEHR into our nursing programs in class, lab, and simulation. EdEHR will allow our students to understand electronic charting, practice documentation, and incorporate it into real-time learning during simulation. In addition, the program allows faculty to review student charting through their LMS system, creating several different learning and assignment opportunities for students.',
    source: 'Kristen Riberdy,  RN, BScN, MEd, CHSE, DNP (ip), Simulation Coordinator, Seneca'
  },
  shaykewich: {
    text: 'Educators have been looking for a project like this for over a decade, so it was a big success for BCcampus to get involved and help build this learning tool, working with the steering committee to bring it to a state where it has broader potential for considerable growth.',
    source: 'David Shaykewich, Manager, DevOps at BCcampus',
    reference: '3'
  },
  jmin: {
    text: 'Providing students with an accurate and effective sandbox gives them exposure to the technology before they go into the real world, and this is a vitally important project for today’s health care practices. It will be great to see this become a meaningful implementation in a variety of different programs for the institutions throughout the province, and with necessary iterative improvements, this has amazing pedagogical potential across all health programs.',
    source: 'Jason Min, lecturer, Faculty of Pharmaceutical Sciences, University of British Columbia',
    reference: '3'
  },
  mbridge: {
    text: 'It\’s not an overstatement to say that this tool will transform the way we teach and learn in the School of Health Sciences.',
    source: 'Michele Bridge, Instructional Development Consultant, Learning & Teaching Centre, BCIT'
  },
  chillary: {
    text: 'EdEHR has presented the Selkirk School of Health and Human Services (HHS) with an open-source and customizable learning platform to better prepare students for digital literacy and competency in health care informatics in the professional setting. Students  have been exposed to learning activities focused on data collection and documentation. Selkirk has been able to incrementally rollout EdEHR in alignment with nursing curriculum at a pace that is comfortable for faculty and students, without an imperative to  adopt a complete homework suite with contextually irrelevant content from a large publisher.',
    source: ' Chris Hillary RN, BSN, M.Ed.HSE, Selkirk College School of Nursing'
  },
  sonja: {
    text: 'The EdEHR program is an excellent Electronic Health Record simulation program. It incorporates all facets of medicine – Nursing, Pharmacy, Laboratory, and even Medical Imaging…Students find the system very user friendly and intuitive… Bryan (EdEHR.org) takes our feedback and can customize exactly what we are looking for.',
    source: 'Sonja Turnbull, Instructor, Hematology, Medical Laboratory Science, BCIT'
  },
  rees: {
    text: 'By providing students with the ability to learn how to use EHRs in the classroom setting, they will ultimately be able to spend more time with their patients in the clinical setting. Students need to learn how to work with anything that’s technologically foreign to them. The first few times students work with an automatic blood pressure cuff or IV pump, they spend more time focused on the technology instead of the patient, but as they use it more, it becomes easier for them to interact with their patients at the same time. The EdEHR project helps them understand the implications and impact of EHRs before they’re in contact with patients.',
    source: 'Glynda Rees, faculty in the Bachelor of Science program at the British Columbia Institute of Technology',
    reference: '3'
  },

  antony: {
    text: 'Students need to be encouraged to think in a different way about electronic documentation. If we’re not training students to think about EHRs, we’re doing them a disservice. When they enter a clinical placement or the workforce, they’ll be expected to know how to use these tools.',
    source: 'Dr. Joseph Anthony, interim associate dean of health professions in the Faculty of Medicine at the University of British Columbia',
    reference: '3'
  },
}

export const demoText = {
  course1Title: 'Sample Course - Health Care',
  course2Title: 'Sample Course - Med Lab',
  personaLabel: 'Your demo persona is',
  roleLabel: 'With role',
  selectUserLabel: 'Select a user',
  lmsTitle: 'Sample LMS',
  lmsHint: 'Typical learning management systems are: Moodle, Canvas, Brightspace, D2L and etc.',
  switchRoleExplain: 'When you click on an activity this \'instructor\' will become a \'student\' same as how Moodle allows users to switch roles.',
  switchRoleLabel: 'Logout and change to another persona',
  switchRoleSegmentTitle: 'Check to switch role:',
  title: 'Demonstration - "Log In"',
  intro: 'Here you can "log in", as a student or instructor, to a simulated learning management system (LMS) and try out the EdEHR. ' +
    ' Once you log in you can choose a sample course with sample activities that use the EdEHR. ' +
    ' In the real world, your school\'s lead instructors will create course content that will use a special connector that will' +
    ' open the EdEHR.\n' +
    'Tip: Log in as a student and do some documentation in the EHR or LIS. Submit your work and then come log in as an instructor to grade your student\'s work.',
  advanced: 'Once you have explored the roles of student and instructor you may wish to try out the role of creator.  We recommend that you read the documentation first. A link to the documentation is in the top banner. We also recommend that you reach out to mailto:info@edehr.org to request a guided tour. ',

  explanation: [
    {
      title: ' ',
      body:     'In the real world, the LMS and EdEHR administrators work together and create a special ' +
        ' LTI connector See [LTI](https://www.imsgloba.org/activity/learning-tools-interoperability).' +
        ' With this connector your lead instructors can create EdEHR activities simply by using it.' +
        ' The EdEHR has some sample case studies to get you started. You will see these samples in this demonstration.' +
        ' Lead instructors can create new case studies, in your instance of EdEHR, to suit your educational needs. You can experiment' +
        ' and create content in this demonstration.  You can even save the content you create and later import it into your EdEHR instance.'
    },
    {
      title: 'Step 1',
      body: ' When an EdEHR activity is created in your LMS it is not yet associated with a case study, what the EdEHR calls a Learning Object. ' +
        ' It is essential that at least one instructor use every new EdEHR activity at least once to make the association in the EdEHR. ' +
        ' This is just like setting up a connection to an online 3rd party text book. At first the connection is to the online system. ' +
        ' You will next want to select a page within the book for the activity. '
    }, {
      title: 'If a student access the activity first ...',
      body: ' If a student selects an EdEHR activity before an instructor has made the connection the student will be asked to contact an ' +
        ' instructor to make the association.  It is best to always have an instructor use all EdEHR activities to make the association' +
        ' with an existing learning object or make a new one.  Go ahead. Log into the demonstration as a student and try an activity. See' +
        ' what your students might see. '
    },
    {
      title: 'Step 2',
      body: ' After an instructor has made the connection with an EdEHR learning object the activity is ready for the class. ' +
        ' Try this demonstration using one or more of the student logins.  Do some work in the EHR ' +
        ' and submit it for evaluation.'
    },
    {
      title: 'Evaluating student work',
      body: ' Then log in as an instructor and review the work your "students" have done. Give your students feedback on ' +
        'what they have submitted. Unlock the submission so the student can resume working. Or block / unblock the entire class. ' +
        'You can review the student\'s work in the EHR system or see a condensed view that shows just their work.'
    },
    {
      title: 'Creating content',
      body: ' As an instructor you can also customize the learning objects and case study data. ' +
        ' To do this log in as an instructor using one of the EdEHR activities in your LMS. ' +
        ' Then use the "Instructors tools" menu and select "Course designer mode" to elevate your permissions from just "instructor" to "content creator".' +
        ' Use the same menu to navigate to the Learning Objects or Case Studies tabs.  Explore how you can modify the instructions given to the students. ' +
        ' Or go deeper and modify the EHR case study content.\n' +
        ' If you do edit the EHR case studies and you want to preserve your work then be sure to use the download button.'
    },
    {
      title: 'Exit',
      body: '' +
        ' Use the Demo menu item on the top banner to leave the demonstration. ' +
        ' When you leave the demonstration all of your demonstration data is reset.' +
        ' Reach out to mailto:info@edehr.org if you have questions.',
    }
  ],
  lmsAside: 'The region inside the blue box is a very simplified learning management system (LMS). It contains sample courses and activities that are connected to EdEHR content.',
  lmsAside2: 'Some of those learning objects are ' +
    ' based on the open text-book <a href="https://pressbooks.bccampus.ca/healthcasestudies/">Health Case Studies - Toward Closing the Healthcare Communication Gap</a> by:  Glynda Rees, Rob Kruger, Janet Morrison.',
  login: {
    title: 'Welcome to your personal EdEHR sandbox',
    body: 'This is a sandbox for you to explore the EdEHR and how it works with learning management systems such as Moodle, Brightspace, etc. ' +
      'This sandbox is only intended for exploring, yet, you can  create content and download a copy for later use. \n' +
      'Please contact mailto:info@edehr.org if you have any questions or need help.\n'
  },
  logout: {
    title: 'Confirm exit demonstration mode',
    body: 'Exit and destroy your demonstration environment. If you created or modified any EHR seeds you may wish to save a copy first. Cancel this dialog and return to the Seeds section (as an instructor) and download copies of your modified seeds. Then exit this demonstration mode.'
  }
}
